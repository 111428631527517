import PropTypes from "prop-types";
import cn from "classnames";
import { useRef } from "react";
import { RemoveScroll } from "react-remove-scroll";
import Div100vh from "react-div-100vh";
import useDetectOutsideClick from "helpers/hooks/useDetectOutsideClick";
import IconClose from "solar/icons/IconClose";
import Container from "components/shared/adventures/Container";
import styles from "components/shared/adventures/Panel.module.scss";

export default function Panel({ isOpen, setIsOpen, children }) {
  const buttonRef = useRef(null);
  const panelRef = useRef(null);
  useDetectOutsideClick(buttonRef, panelRef, () => setIsOpen(false));

  return (
    <div
      ref={panelRef}
      className={cn(styles.component, isOpen ? styles.visible : styles.hidden)}
    >
      <RemoveScroll enabled={isOpen}>
        <Div100vh className={styles.scroll}>
          <Container paddingY={false} className={styles.close}>
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              ref={buttonRef}
            >
              <IconClose />
            </button>
          </Container>
          <Container paddingY={false} className={styles.content}>
            {children}
          </Container>
        </Div100vh>
      </RemoveScroll>
    </div>
  );
}

Panel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
