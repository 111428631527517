import React, { useState, useEffect, useRef, useContext } from "react";
import { VideoCardContext } from "context/video-card-context";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { useRouter } from "next/router";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import PhotoCloudfront from "components/shared/adventures/PhotoCloudfront";
import IconArrowRightSm from "solar/icons/IconArrowRightSm";
import Title from "components/shared/adventures/Title";
import styles from "components/shared/adventures/VideoCardAdventure.module.scss";

export default function VideoCardAdventure({
  slug,
  name,
  country,
  photoCover,
  photoFirstFrame = "",
  videoMp4 = "",
  videoWebm = "",
  priority = false,
  defaultPlaying = false,
  makeActive,
  playWithinView,
  pauseOnMouseOut = true,
  path = "adventures",
}) {
  const href = `/${path}/${slug}`;
  const { activeVideo, setActiveVideo } = useContext(VideoCardContext);

  const greaterThanLg = useMediaQuery({
    query: `(min-width: ${styles.solBreakpointLg}px)`,
  });
  const router = useRouter();
  const videoRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [posterIsReady, setPosterIsReady] = useState(false);

  const [adventureRef, adventureInView] = useInView({
    threshold: 0.6,
    initialInView: true,
  });

  useEffect(() => {
    const videoEl = videoRef.current;

    if (videoEl) {
      videoEl.addEventListener("timeupdate", (event) => {
        if (event.timeStamp > 0) {
          // delay showing the video because safari object-fit bug causes flash of unsized content
          setTimeout(() => {
            setIsVideoLoaded(true);
          }, 200);
        }
      });
    }

    return () => {
      if (videoEl) {
        videoEl.removeEventListener("timeupdate", null);
      }
    };
  }, []);

  async function playMedia() {
    try {
      await videoRef.current.play();
    } catch (err) {
      // handle error
    }
  }

  async function pauseMedia(noReset) {
    if (videoRef.current) {
      try {
        await videoRef.current.pause();
        if (!noReset) {
          videoRef.current.currentTime = 0;
        }
      } catch (err) {
        // handle error
      }
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      if (activeVideo === videoRef) {
        playMedia();
      } else {
        pauseMedia();
      }
    }
  }, [activeVideo]);

  // start video playing if `defaultPlaying` is true
  useEffect(() => {
    if (defaultPlaying) {
      setActiveVideo(videoRef);
    }
  }, [defaultPlaying, setActiveVideo]);

  function handleClick() {
    pauseMedia(true);
    router.push(href);
  }

  useEffect(() => {
    if (playWithinView && adventureInView && !greaterThanLg) {
      setActiveVideo(videoRef);
    }
  }, [adventureInView, setActiveVideo, greaterThanLg, playWithinView]);

  // start playing this video if makeActive gets set to true
  useEffect(() => {
    if (makeActive) {
      setActiveVideo(videoRef);
    }
  }, [makeActive, setActiveVideo]);

  return (
    <div
      ref={adventureRef}
      className={styles.component}
      onMouseEnter={() => {
        if (greaterThanLg) {
          setActiveVideo(videoRef);
        }
      }}
      onMouseLeave={() => {
        if (greaterThanLg && pauseOnMouseOut) {
          setActiveVideo(null);
        }
      }}
    >
      <button
        type="button"
        className={styles.link}
        onClick={() => handleClick()}
      >
        {!posterIsReady && photoFirstFrame?.url && videoMp4 && videoWebm && (
          <PhotoCloudfront
            src={photoFirstFrame.url}
            width={photoFirstFrame.width}
            height={photoFirstFrame.height}
            className={cn(styles.visual, styles.lqip)}
            sizes="1vw"
            alt="Thermal adventure"
            priority
            placeholder="empty"
          />
        )}
        {photoCover?.url && (
          <PhotoCloudfront
            src={photoCover.url}
            width={photoCover.width}
            height={photoCover.height}
            sizes={[["lg", "100vw"], ["33vw"]]}
            className={cn(
              styles.visual,
              styles.poster,
              isVideoLoaded && activeVideo === videoRef && styles.hidden
            )}
            alt="Thermal adventure"
            priority={priority}
            onLoadingComplete={() => setPosterIsReady(true)}
          />
        )}

        {videoMp4 && videoWebm && (
          <video
            ref={videoRef}
            preload="auto"
            muted
            loop
            autoPlay
            playsInline
            poster="/shared/transparent-square.png"
            className={styles.visual}
          >
            <source src={videoMp4.url} type={videoWebm.content_type} />
            <source src={videoWebm.url} type={videoWebm.content_type} />
          </video>
        )}
        <div className={styles.info}>
          <div className={styles.overlay} />
          <div className={styles.top}>
            <p className={styles.country}>{country}</p>
            <Title tag="h2" className={styles.name}>
              {name}
            </Title>
          </div>
          <div className={styles.bottom}>
            <div className={styles.cta}>
              Explore&nbsp;
              <IconArrowRightSm />
            </div>
          </div>
        </div>
      </button>
    </div>
  );
}

VideoCardAdventure.propTypes = {
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  photoCover: PropTypes.shape({
    url: PropTypes.string,
    content_type: PropTypes.string,
    byte_size: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  photoFirstFrame: PropTypes.shape({
    url: PropTypes.string,
    content_type: PropTypes.string,
    byte_size: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  videoMp4: PropTypes.shape({
    url: PropTypes.string,
    content_type: PropTypes.string,
    byte_size: PropTypes.number,
  }),
  videoWebm: PropTypes.shape({
    url: PropTypes.string,
    content_type: PropTypes.string,
    byte_size: PropTypes.number,
  }),
  priority: PropTypes.bool,
  defaultPlaying: PropTypes.bool,
  makeActive: PropTypes.bool,
  playWithinView: PropTypes.bool,
  pauseOnMouseOut: PropTypes.bool,
  path: PropTypes.string,
};
