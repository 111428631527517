import PropTypes from "prop-types";
import cn from "classnames";
import styles from "components/shared/adventures/Container.module.scss";

export default function Container({
  children,
  paddingX = true,
  paddingY = true,
  className = null,
}) {
  return (
    <div
      className={cn(
        styles.component,
        paddingX && styles.paddingX,
        paddingY && styles.paddingY,
        className
      )}
    >
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  paddingX: PropTypes.bool,
  paddingY: PropTypes.bool,
  className: PropTypes.node,
};
