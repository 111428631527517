import PropTypes from "prop-types";
import cn from "classnames";
import Link from "next/link";
import Container from "components/shared/adventures/Container";
import styles from "components/shared/adventures/Footer.module.scss";

export default function Footer({ color = "orange" }) {
  return (
    <div className={cn(styles.component, styles[color])}>
      <Container paddingY={false}>
        <div className={styles.links}>
          <div className={styles.link}>
            <a href="tel:1-415-534-1527">(415) 534-1527</a>
          </div>
          <div className={styles.link}>
            <Link href="/journal" target="_blank">
              Stories
            </Link>
          </div>
          <div className={styles.link}>
            <Link href="/about-thermal">About Thermal</Link>
          </div>
          <div className={styles.link}>
            <Link href="/stays" target="_blank">
              Stays
            </Link>
          </div>
          <div className={styles.link}>
            <Link href="/privacy" target="_blank">
              Privacy
            </Link>
            <span className={styles.faux}> and </span>
            <Link href="/terms" target="_blank" className={styles.link}>
              terms
            </Link>
          </div>
        </div>
        <img
          src="/adventures/logo.svg"
          alt="Thermal"
          className={styles.logo}
          width="1305"
          height="316"
        />
      </Container>
    </div>
  );
}

Footer.propTypes = {
  color: PropTypes.oneOf(["orange", "yellow"]),
};
