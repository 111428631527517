import PropTypes from "prop-types";
import cn from "classnames";
import styles from "components/shared/adventures/Title.module.scss";

export default function Title({ tag, children, className = null }) {
  const Tag = tag;

  return (
    <Tag className={cn(styles.component, styles[tag], className)}>
      {children}
    </Tag>
  );
}

Title.propTypes = {
  tag: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.node,
};
