import * as React from "react";

function IconArrowRightSm() {
  return (
    <svg
      width={9}
      height={8}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.259 4.002a.466.466 0 00-.157-.344L5.235.938a.513.513 0 00-.353-.15c-.28 0-.48.187-.48.44 0 .132.057.244.144.326l.982.945 1.265 1.097-1.012-.058H.493c-.292 0-.493.19-.493.464 0 .27.2.46.493.46h5.288l1.012-.058L5.528 5.5l-.982.945a.445.445 0 00-.144.327c0 .252.2.439.48.439.122 0 .24-.046.345-.141l2.875-2.73a.464.464 0 00.157-.339z" />
    </svg>
  );
}

export default IconArrowRightSm;
