import PropTypes from "prop-types";
import styles from "solar/components/Break.module.scss";

export default function Break({ small = false }) {
  return small ? (
    <>
      <br className={styles.breakSm} />
      <span className={styles.spaceSm}> </span>
    </>
  ) : (
    <>
      <br className={styles.breakLg} />
      <span className={styles.spacelg}> </span>
    </>
  );
}

Break.propTypes = {
  small: PropTypes.bool,
};
