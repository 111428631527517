/* eslint-disable import/prefer-default-export */

export function humanProductLabel(p) {
  switch (p) {
    case "Lodge":
      return "Room";
    case "Boat":
      return "Route";
    default:
      return p;
  }
}

export function parseAdventureName(name) {
  if (name.includes("Maryam el Gardoum")) {
    return name.replace("el ", "");
  }

  return name;
}
