import styles from "components/shared/adventures/BannerPhone.module.scss";

export default function BannerPhone() {
  return (
    <div className={styles.component}>
      Text or call to plan your dream trip:&nbsp;
      <a href="tel:1-415-534-1527" className={styles.number}>
        (415) 534-1527
      </a>
    </div>
  );
}
