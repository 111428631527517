import PropTypes from "prop-types";
import cn from "classnames";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { trackHomeAdventureLeadSubmission } from "lib/analytics";
import Panel from "components/shared/adventures/Panel";
import PanelForm from "components/shared/adventures/PanelForm";
import PanelSuccess from "components/shared/adventures/PanelSuccess";
import Navbar from "components/shared/adventures/Navbar";
import styles from "components/shared/adventures/Navigation.module.scss";

export default function Navigation({
  formSource = "undefined",
  positionAbsolute = false,
  darkText = false,
  adventures,
}) {
  const [panel, setPanel] = useState("select");
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = (p) => {
    setPanel(p);
    setIsOpen(!isOpen);
  };

  const [navbarRef, navbarInView] = useInView({
    threshold: 0,
    initialInView: true,
  });

  return (
    <>
      <div
        className={cn(
          styles.navbar,
          darkText && styles.darkText,
          positionAbsolute && styles.positionAbsolute
        )}
        ref={navbarRef}
      >
        <Navbar />
      </div>
      <div
        className={cn(
          styles.actions,
          navbarInView ? styles.actionsHidden : styles.actionsVisible
        )}
      >
        <button
          type="button"
          className={styles.button}
          onClick={() => toggleOpen("form")}
        >
          Start here
        </button>
      </div>
      <Panel isOpen={isOpen} setIsOpen={setIsOpen}>
        {panel === "form" && (
          <PanelForm
            updatePanelState={setPanel}
            onSubmitCallback={() => trackHomeAdventureLeadSubmission()}
            formSource={formSource}
            adventures={adventures}
          />
        )}
        {panel === "formSuccess" && <PanelSuccess />}
      </Panel>
    </>
  );
}

Navigation.propTypes = {
  formSource: PropTypes.string,
  darkText: PropTypes.bool,
  positionAbsolute: PropTypes.bool,
  adventures: PropTypes.arrayOf(
    PropTypes.shape({
      destination_name: PropTypes.string,
    })
  ).isRequired,
};
